import { IProfilesResult } from 'models/profiles.model';
import { bcApi } from './_api';

export const accountApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    linkLovedOne: builder.mutation<unknown, { code: string }>({
      query: (data) => ({
        url: '/account_relationships/confirm_code_for_lo',
        method: 'POST',
        data,
      }),
    }),
    sendInviteToCaregiver: builder.mutation<
      unknown,
      { profileId: number; email: string; message?: string }
    >({
      query: ({ profileId, email, message }) => ({
        url: '/account_relationships/send_caregiver_invite_email',
        method: 'POST',
        data: {
          patient_profile_id: profileId,
          email,
          custom_message: message,
        },
      }),
    }),
    disconnectCaregiver: builder.mutation<unknown, { relationshipId: number }>({
      query: ({ relationshipId }) => ({
        url: `/account_relationships/${relationshipId}`,
        method: 'DELETE',
      }),
    }),
    updatePrimaryCaregivers: builder.mutation<
      unknown,
      { caregiverIds: number[]; profileId: number }
    >({
      query: ({ caregiverIds, profileId }) => ({
        url: '/account_relationships/update_primary_caregivers',
        method: 'PUT',
        data: {
          patient_profile_id: profileId,
          primary_caregiver_ids: caregiverIds,
        },
      }),
    }),
    removeEmergencyContact: builder.mutation<
      { result: IProfilesResult },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/account_relationships/${id}/remove_emergency_contact`,
        method: 'PUT',
      }),
    }),
    setEmergencyContact: builder.mutation<
      { result: IProfilesResult },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/account_relationships/${id}/set_emergency_contact`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useDisconnectCaregiverMutation,
  useLinkLovedOneMutation,
  useSendInviteToCaregiverMutation,
  useUpdatePrimaryCaregiversMutation,
  useRemoveEmergencyContactMutation,
  useSetEmergencyContactMutation,
} = accountApi;
