import { IAccountRequest, IAccountResponse } from 'models/account.model';
import {
  IAccountRelationships,
  IProfilesResponse,
} from 'models/profiles.model';
import { ITrackingResponse } from 'models/tracking.model';
import { bcApi } from './_api';

export const profilesApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getLinkedProfiles: builder.query<
      IAccountRelationships,
      { caregiverId: number }
    >({
      query: (data) => ({
        url: '/account_relationships',
        method: 'GET',
        params: { caregiver_account_id: data.caregiverId },
      }),
      transformResponse: (response: IProfilesResponse) => ({
        accounts: response.meta.accounts,
        results: response.results,
      }),
    }),
    getLinkedCaregivers: builder.query<
      IAccountRelationships,
      { profileId: number }
    >({
      query: ({ profileId }) => ({
        url: '/account_relationships',
        method: 'GET',
        params: { patient_profile_id: profileId },
      }),
      transformResponse: (response: IProfilesResponse) => ({
        accounts: response.meta.accounts,
        results: response.results,
      }),
    }),
    getProfile: builder.query<IAccountResponse, { id: number }>({
      query: (data) => ({
        url: `/accounts/${data.id}`,
        method: 'GET',
      }),
      providesTags: ['ProfileData'],
    }),
    updateProfile: builder.mutation<IAccountResponse, IAccountRequest>({
      query: (data) => ({
        url: `/accounts/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['ProfileData'],
    }),
    startTracking: builder.mutation<ITrackingResponse, { id: number }>({
      query: (data) => ({
        url: `/accounts/${data.id}/start_emergency_tracking`,
        method: 'PUT',
      }),
    }),
    stopTracking: builder.mutation<ITrackingResponse, { id: number }>({
      query: (data) => ({
        url: `/accounts/${data.id}/stop_emergency_tracking`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetLinkedCaregiversQuery,
  useGetLinkedProfilesQuery,
  useGetProfileQuery,
  useLazyGetLinkedCaregiversQuery,
  useLazyGetLinkedProfilesQuery,
  useLazyGetProfileQuery,
  useStartTrackingMutation,
  useStopTrackingMutation,
  useUpdateProfileMutation,
} = profilesApi;
