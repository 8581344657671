// keep the values empty to avoid type issues on env variables

const config = {
  REACT_APP_NODE_ENV: 'development',
  REACT_APP_BH_KEY:
    'd065bdccfafae426d47d0f46bc2d547eb02ef5a10b6e2ec7108ebb29328ac820',
  REACT_APP_API_URL: 'https://api-staging.beatricehealth.com/api/v1',
  REACT_APP_IOS_MAP_KEY:
    'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJQWlI1VTQ0R1IifQ.eyJpc3MiOiI3TDZUVzY5Q0JGIiwiaWF0IjoxNjkwNDcxNTk0LCJleHAiOjE3MjIwMzg0MDB9.Ux3VAvDIlr6nyNvULN-3Xo19SgX6jPxCC7E8luiJGfAwpFU15aXMK-jdHw-6tm8zQY1FvMzRWHc5WjMt2Tf68Q',
  MUI_KEY:
    '264879265fe54389f7ab924c2474e0c1Tz01OTg0MyxFPTE3MDgwMDY2MTI5NjQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  REACT_APP_BH_KEY_ALTERNATE:
    'd065bdccfafae426d47d0f46bc2d547eb02ef5a10b6e2ec7108ebb29328ac820',
  REACT_APP_API_URL_ALTERNATE:
    'https://api-staging.secure.beatricehealth.com/api/v1',
  REACT_APP_STAGING_URL: 'https://app-staging.boundarycare.com',
  REACT_APP_SECURE_STAGING_URL: 'https://app-staging.secure.boundarycare.com',
  REACT_APP_PROD_URL: 'https://app.boundarycare.com',
  REACT_APP_SECURE_PROD_URL: 'https://app.secure.boundarycare.com',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const env: { NODE_ENV: string; [x: string]: string } = {
  ...config,
  ...process.env,
};

export default env;
