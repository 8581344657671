import { useProfileData } from 'hooks/use-profile-data';
import { routes } from 'nav';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from 'store/api/profiles.api';

export const usePatientInfo = () => {
  const { profileId } = useParams();
  const navigate = useNavigate();

  useProfileData();

  const [update, { isLoading, isError, isSuccess }] =
    useUpdateProfileMutation();
  const { data: profileData, isError: patientLoadingError } =
    useGetProfileQuery(
      {
        id: parseInt(profileId ?? '0'),
      },
      { skip: !profileId },
    );

  const handleUpdateProfile = ({
    firstName,
    lastName,
    identifier,
  }: {
    firstName: string;
    lastName: string;
    identifier: string;
  }) => {
    update({
      id: parseInt(profileId ?? '0'),
      last_name: lastName,
      first_name: firstName,
      third_party_public_identifier: identifier,
    });
  };

  useEffect(() => {
    if (isError && !isLoading) {
      toast.error('Update failed. Try again.');
    }

    if (isSuccess && !isLoading) {
      toast.success('Patient settings were successfully updated.');
    }
  }, [isLoading]);

  useEffect(() => {
    if (patientLoadingError) {
      toast.error(
        'A patient with the provided identifier could not be found. Please try again.',
      );
      navigate(routes.private.patientsOverview.href);
    }
  }, [patientLoadingError]);

  return {
    firstName: profileData?.result.first_name,
    lastName: profileData?.result.last_name,
    customIdentifier: profileData?.result.third_party_public_identifier,
    handleUpdateProfile,
  };
};
